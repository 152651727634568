<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <h1>
          <b-badge class="mr-1" variant="primary">宿舍：{{ block }} 号楼 {{ roomId }}</b-badge>
        </h1>
      </div>
    </div>
    <!--begin::Dashboard-->
    <div class="my-panel row" v-if="!notChoose">
      <div
          class="my-bed col-xl-5"
          v-for="(item, index) in bedInfoList"
          :key="index"
      >
        <div class="text-content" v-if="item !== ''">
          <label class="my-text left-top">序号：{{ numList[index] }}号床</label>
          <label class="my-text"
          >状态：{{ item.choose ? "已选" : "未选" }}</label
          >
          <label class="my-text">姓名：{{ item.name }}</label>
          <label class="my-text">习惯：{{ item.habit }}</label>
          <label class="my-text">专业：{{ item.major }}</label>
          <label class="my-text">生源：{{ item.studentSourceDetail }}</label>
        </div>
        <p class="none-select" v-if="item === ''">
          暂时还没人选择{{ numList[index] }}号床
        </p>
      </div>
    </div>
    <div class="my-panel row" v-if="notChoose">
      <label class="my-warning">{{ roomWarning }}</label>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      bedInfoList: ["", "", "", ""],
      notChoose: true,
      numList: ["一", "二", "四", "三"],
      roomId: "",
      block: "",
      roomWarning: "您当前还未选择宿舍,请选择宿舍",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);
    this.initData();
  },
  methods: {
    initData() {
      this.$axios({
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: sessionStorage.getItem("systemToken"),
        },
        url: "/api/user/checkPayStatusByUserId", //后端请求的路径
        method: "post",
      }).then((res) => {
        console.log(res)
        if (res.payStatus != 0) {
          this.$message({
            message: "您还未完成缴费，请先缴费！",
            type: "error",
          });
          this.roomWarning = '您当前还未完成缴费，请前往缴费或联系老师！！！'
        } else {
          this.$axios({
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: sessionStorage.getItem("systemToken"),
            },
            url: "/api/bed/getRoomBedsByUserId", //后端请求的路径
            method: "post",
          }).then((response) => {
            if (response.status == 0) {
              const bedInfoList = response.data;
              for (let index in bedInfoList) {
                if (bedInfoList[index] != null) {
                  if (bedInfoList[index].type == 1) {
                    this.$set(this.bedInfoList, 0, bedInfoList[index]);
                  }
                  if (bedInfoList[index].type == 2) {
                    this.$set(this.bedInfoList, 1, bedInfoList[index]);
                  }
                  if (bedInfoList[index].type == 3) {
                    this.$set(this.bedInfoList, 3, bedInfoList[index]);
                  }
                  if (bedInfoList[index].type == 4) {
                    this.$set(this.bedInfoList, 2, bedInfoList[index]);
                  }
                }
              }
              this.roomId = response.roomId;
              this.block = response.block;
              this.notChoose = false;
            } else {
              this.notChoose = true;
            }
          });
        }
      });
      // this.$axios({
      //   headers: {
      //     "Content-Type": "application/json;charset=UTF-8",
      //     Authorization: sessionStorage.getItem("systemToken"),
      //   },
      //   url: "/api/bed/getRoomBedsByUserId", //后端请求的路径
      //   method: "post",
      // }).then((response) => {
      //   if (response.status == 0) {
      //     const bedInfoList = response.data;
      //     for (let index in bedInfoList) {
      //       if (bedInfoList[index] != null) {
      //         if (bedInfoList[index].type == 1) {
      //           this.$set(this.bedInfoList, 0, bedInfoList[index]);
      //         }
      //         if (bedInfoList[index].type == 2) {
      //           this.$set(this.bedInfoList, 1, bedInfoList[index]);
      //         }
      //         if (bedInfoList[index].type == 3) {
      //           this.$set(this.bedInfoList, 3, bedInfoList[index]);
      //         }
      //         if (bedInfoList[index].type == 4) {
      //           this.$set(this.bedInfoList, 2, bedInfoList[index]);
      //         }
      //       }
      //     }
      //     this.roomId = response.roomId;
      //     this.block = response.block;
      //     this.notChoose = false;
      //   } else {
      //     this.notChoose = true;
      //   }
      // });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
<style>
.el-select {
  width: 100%;
  margin: 10px 0px 10px 0px;
}

.el-input__inner {
  height: 35px;
}

.el-input--suffix {
  font-size: 15px;
}

.el-select-dropdown__item {
  font-size: 13px;
}

html {
  font-size: 15px;
}

@media (min-width: 1200px) {
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
    max-width: 48%;
  }
}
</style>
<style scoped>
.none-select {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-radius: 8px;
  color: grey;
}

.my-content {
}

.my-panel {
  margin: auto;
  width: 100%;
  height: 100%;
  background: #3699ff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.my-bed {
  height: 13rem;
  background: aliceblue;
  border-radius: 8px;
  display: flex;
  margin: 10px 10px 10px 10px;
  overflow: auto;
  text-align: center;
  width: 100%;
}

.my-bed::first-line {
  margin: 10px 10px 10px 10px !important;
}

.my-text {
  text-align: center;
  min-width: 88px;
  width: 45%;
  margin-bottom: 0;
  font-family: "微软雅黑", "黑体", "宋体";
  font-size: 1.25rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  -webkit-box-align: center;

  display: -moz-box;
  -moz-box-orient: horizontal;
  -moz-box-pack: center;
  -moz-box-align: center;

}

.my-warning {
  text-align: center;
  width: 100%;
  font-family: "微软雅黑", "黑体", "宋体";
  font-size: 1.1rem;
  height: 50px;
  line-height: 50px;
  font-weight: 500;
  color: #fff;
}

.my-row {
  width: 100%;
  height: 13rem;
}

.my-button {
  position: relative;
  margin: 0 auto;
  transform: translateY(-50%);
  top: 50%; /*偏移*/
  font-size: 2rem;
  border-radius: 8px;
}

.text-content {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
